import classes from './Event.module.css';
import React, { useContext, useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { DropdownComponent, EventInput, Input, MdEditor, RadioButtons } from '../../components/Inputs/Input';
import MapComponent from '../../components/Map/MapComponent';
import { FormDialog } from '../../components/FormDialog/FormDialog';
import BackupIcon from '@material-ui/icons/Backup';
import { Fab } from '@material-ui/core';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import moment from 'moment';
import { EventFormInitialValues, EventFormValidation } from '../../validation/EventFormValidation';
import { EventService } from '../../services/EventService';
import Loader from '../../components/Loader/Loader';
import { EventContext } from '../../context/EventContext';
import { toast } from 'react-toastify';
import CreateEventMapComponent from '../../components/Map/CreateEventMapComponent';
import LocationAutoComplete from '../../components/LocationAutoComplete/LocationAutoComplete';
import TimezoneSelect from 'react-timezone-select';
import momentTimeZone from 'moment-timezone';

const MEMBER_TYPES = [
  {
    label: 'Patron',
    value: 'patron',
  },
  {
    label: 'Sponsor',
    value: 'sponsor',
  },
  {
    label: 'Recent Graduate',
    value: 'recentGraduate',
  },
  {
    label: 'Individual',
    value: 'individual',
  },

  {
    label: 'Non Member',
    value: 'inActive',
  },
];

const memberCosts = ['patron', 'sponsor', 'recentGraduate', 'individual'];

const CreateEventForm = ({ handleClickUpload }) => {
  const { state, dispatch } = useContext(EventContext);
  const [initialValues, setInitialValues] = useState({});
  const [locationError, setLocationError] = useState('');

  const [disableSubmit, setDisableSubmit] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dates, setDates] = useState({
    startDate: moment(),
    endDate: moment(),
    rsvpDate: moment(),
  });
  const [timezone, setTimezone] = useState({ value: Intl.DateTimeFormat().resolvedOptions().timeZone });

  const [isMarkerShown, setIsMarkerShown] = useState(false);
  const [address, setAddress] = useState({
    lat: '',
    lng: '',
    name: '',
  });

  const startLoading = () => setLoading(true);
  const stopLoading = () => setLoading(false);

  useState(() => {
    const myEvent = state.event;
    if (myEvent.id) {
      let processedValues = { ...myEvent };
      processedValues.costType = 'others';
      processedValues.eventType = processedValues.eventInfo.type;
      processedValues.url = processedValues.eventInfo.url;
      if (myEvent.location.lat) processedValues.eventPlace = 'nonVirtual';
      setTimezone({
        value: 'ETC/GMT',
      });

      setDates({
        startDate: moment.utc(myEvent.startDate),
        endDate: moment.utc(myEvent.endDate),
        rsvpDate: moment.utc(myEvent.rsvpDate),
      });
      setInitialValues(processedValues);
    }
  }, []);

  useEffect(() => {
    // const myEvent = state.event;
    // console.log(timezone)
    // if (!myEvent.id) {
    //   setDates((prev) => ({
    //     startDate: momentTimeZone(prev.startDate).tz(timezone?.value),
    //     endDate: momentTimeZone(prev.endDate).tz(timezone?.value),
    //     rsvpDate: momentTimeZone(prev.rsvpDate).tz(timezone?.value),
    //   }))
    // } else {
    //   setDates((prev) => ({
    //     startDate: momentTimeZone(myEvent.startDate).tz(timezone?.value),
    //     endDate: momentTimeZone(myEvent.endDate).tz(timezone?.value),
    //     rsvpDate: momentTimeZone(myEvent.rsvpDate).tz(timezone?.value),
    //   }))
    // }
  }, [timezone]);

  const mapContent = (values, submitCount, props) => {
    return (
      <>
        <div>
          <LocationAutoComplete setLocation={handleLocationChange} />
        </div>
        <div className={classes.MapContainer}>
          <div className={classes.Title}>Mark the co-ordinates here</div>
          <CreateEventMapComponent isMarkerShown={isMarkerShown} draggable location={address} setLocation={handleLocationChange} />
        </div>
      </>
    );
  };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(({ coords }) => {
      const { latitude: lat, longitude: lng } = coords;
      setAddress({
        lat,
        lng,
      });
      delayedShowMarker();
    });
  }, []);

  const handleSubmit = (values) => {
    const myEvent = state.event;

    setDisableSubmit(true);
    setLocationError('');
    let processedValues = JSON.parse(JSON.stringify(values));
    processedValues.eventInfo = {};
    processedValues.location = { ...address };
    if (processedValues.eventPlace === 'nonVirtual' && !address.name) {
      setLocationError('Location name is required');
      setDisableSubmit(false);
      return;
    }

    Object.keys(dates).map(
      (key) => (processedValues[key] = momentTimeZone(dates[key].format('YYYY-MM-DDTHH:mm:ss')).tz(timezone?.value, true).utc().format())
    );

    if (processedValues.eventPlace !== 'nonVirtual') processedValues.location = {};
    if (!processedValues.cost) processedValues.cost = {};
    if (processedValues.costType && processedValues.costType === 'member') {
      memberCosts.map((key) => (processedValues.cost[key] = processedValues.cost.member));
      if (!processedValues.cost.inActive && processedValues.cost.inActive !== 0) processedValues.cost.inActive = null;
    } else {
      const priceTypes = MEMBER_TYPES.map(({ value }) => value);
      priceTypes.forEach((type) => {
        if (!processedValues.cost[type] && processedValues.cost[type] !== 0) processedValues.cost[type] = null;
      });
    }
    processedValues['eventInfo']['type'] = processedValues.eventType;
    if (processedValues.url) processedValues['eventInfo']['url'] = processedValues.url || null;
    if (state.event && state.event.startDate && state.event.startDate !== processedValues.startDate) processedValues.isPostponed = true;
    CreateEvent(processedValues);
  };

  const CreateEvent = (values) => {
    delete values.cost.member;
    Object.keys(values.cost).forEach((key) => {
      if (!values.cost[key] && values.cost[key] !== 0) values.cost[key] = null;
      else values.cost[key] = values.cost[key].toString();
    });
    if (state.event.id) EventService.Update(state.event.id, values, startLoading, handleCreateSuccess, handleError, stopLoading);
    else EventService.Create(values, startLoading, handleCreateSuccess, handleError, stopLoading);
  };

  const handleCreateSuccess = (res) => {
    setDisableSubmit(false);
    if (!state.event.id) toast.success('Event Created!');
    else toast.success('Event Updated');
  };

  const handleError = (err) => {
    setDisableSubmit(false);
    console.log(err);
  };

  const handleLocationChange = (val) => {
    setAddress(val);
    delayedShowMarker();
  };

  const delayedShowMarker = () =>
    setTimeout(() => {
      setIsMarkerShown(true);
    }, 1500);
  const handleSetTimeZone = (val) => {
    console.log(val);
    setTimezone(val);
  };

  return (
    <div className={classes.CreateEventForm}>
      <Loader show={loading} />
      <div className={classes.Title}>
        <div>
          <span className='brown'>{!state.event.id ? 'Create' : 'Edit'}</span> Event
        </div>
        <div>
          <Fab className={classes.fab} mx={2} size='medium' color='secondary' aria-label='Add'>
            <BackupIcon onClick={() => handleClickUpload()} />
          </Fab>
        </div>
      </div>
      <div className={classes.FormContainer}>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={EventFormValidation}
          onSubmit={(val) => handleSubmit(val)}
        >
          {({ values, submitCount, ...props }) => (
            <Form>
              <FormDialog
                onClose={() => setShowModal(false)}
                show={showModal}
                title={`Select Location`}
                text={''}
                content={mapContent(values, submitCount, props)}
              />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  gridColumnGap: '1vw',
                }}
                className={classes.Inputs}
              >
                <Input name='title' label='Title' submitCount={submitCount} required />
                <Input name='image' label='Eventpage Image' submitCount={submitCount} required />
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  gridColumnGap: '1vw',
                }}
                className={classes.Inputs}
              >
                <Input name='homepageImage' label='Homepage Image' submitCount={submitCount} required />
                <Input name='totalTickets' label='Total Tickets' submitCount={submitCount} required />
              </div>
              <div className={classes.Description + ' ' + classes.Inputs}>
                <MdEditor
                  required={true}
                  name='description'
                  label='Description'
                  setFieldValue={props.setFieldValue}
                  setFieldTouched={props.setFieldTouched}
                  getFieldMeta={props.getFieldMeta}
                  markdown={values.description}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  gridColumnGap: '1vw',
                }}
                className={classes.Inputs}
              >
                <div className='radio-container'>
                  <div className={classes.Label}>Event Place</div>
                  <RadioButtons
                    name='eventPlace'
                    defaultValue={initialValues.eventPlace}
                    setFieldValue={props.setFieldValue}
                    options={[
                      { label: 'Virtual', value: 'virtual' },
                      { label: 'Non Virtual', value: 'nonVirtual' },
                    ]}
                    values={values}
                  />
                </div>
                <div>
                  <div className={classes.Label}>Event Type</div>
                  <RadioButtons
                    name='eventType'
                    defaultValue={initialValues.eventType}
                    setFieldValue={props.setFieldValue}
                    options={[
                      { label: 'Seminar', value: 'seminar' },
                      { label: 'Webinar', value: 'webinar' },
                      { label: 'Third Party', value: 'thirdParty' },
                    ]}
                    values={values}
                  />
                </div>
              </div>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  gridColumnGap: '1vw',
                }}
                className={classes.Inputs}
              >
                {values.eventPlace === 'nonVirtual' ? (
                  <div onClick={() => setShowModal(true)} style={{ cursor: 'pointer' }}>
                    <Input
                      name='location'
                      label='location'
                      value={Object.values(address).join(',')}
                      submitCount={submitCount}
                      required
                      disabled
                    />
                    <div>{locationError ? <div className='error_msg'>{locationError}</div> : null}</div>
                  </div>
                ) : (
                  <div></div>
                )}
                {values.eventType !== 'seminar' ? (
                  <Input name='url' label='url' required={values.eventType === 'thirdParty'} submitCount={submitCount} />
                ) : null}
              </div>

              <div className='border'></div>
              <p className='title-text-small brown' style={{ margin: '2em auto' }}>
                Event Timings
              </p>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  gridColumnGap: '1vw',
                }}
              >
                <div>
                  <TimezoneSelect
                    // label="Timezone"
                    labelStyle={'original'}
                    value={timezone.value}
                    helperText='Please select a timezone from the list'
                    onChange={(val) => {
                      handleSetTimeZone(val);
                    }}
                  />
                </div>
                <div className={classes.timeSelector}>
                  <div>
                    <KeyboardDateTimePicker
                      value={dates.startDate}
                      onChange={(date, value) => setDates({ ...dates, startDate: date })}
                      label='Start Date'
                      onError={console.log}
                      minDate={dates.rsvpDate}
                      format='yyyy/MM/DD hh:mm a'
                      minDateMessage='Date should be after RSVP date'
                    />
                  </div>
                  {/* <div>
                    <TimezoneSelect
                      labelStyle={'original'}
                      value={timezone.startDate.value}
                      label="Timezone"
                      helperText="Please select a timezone from the list"
                      onChange={(val) => { handleSetTimeZone('start', val) }}
                    />
                  </div> */}
                </div>
                <div className={classes.timeSelector}>
                  <div>
                    <KeyboardDateTimePicker
                      value={dates.endDate}
                      onChange={(date, value) => setDates({ ...dates, endDate: date })}
                      label='End Date'
                      onError={console.log}
                      minDate={dates.startDate}
                      format='yyyy/MM/DD hh:mm a'
                      minDateMessage='Date should be after start date'
                    />
                  </div>
                  {/* <div>
                    <TimezoneSelect
                      labelStyle={'original'}
                      value={timezone.endDate.value}
                      // label="Timezone"
                      helperText="Please select a timezone from the list"
                      onChange={(val) => { handleSetTimeZone('end', val) }}
                    />
                  </div> */}
                </div>
                <div className={classes.timeSelector}>
                  <div>
                    <KeyboardDateTimePicker
                      value={dates.rsvpDate}
                      onChange={(date, value) => setDates({ ...dates, rsvpDate: date })}
                      label='RSVP Date'
                      onError={console.log}
                      format='yyyy/MM/DD hh:mm a'
                    />
                  </div>
                </div>
              </div>

              <div className='border'></div>
              <p className='title-text-small brown' style={{ margin: '2em auto' }}>
                Event Cost
              </p>
              <div>
                <div>
                  <div className='radio-container'>
                    <RadioButtons
                      name='costType'
                      setFieldValue={props.setFieldValue}
                      options={[
                        { label: 'Specify cost', value: 'others' },
                        { label: 'All', value: 'member' },
                      ]}
                      values={values}
                      onChange={() => props.setFieldValue('cost', '')}
                    />
                  </div>
                  <div className={classes.EventCosts}>
                    {values.costType === 'member' ? (
                      <div>
                        <EventInput name='cost.member' label='Member' type='number' />
                        <EventInput name='cost.inActive' label='Non Member' type='number' />
                      </div>
                    ) : (
                      <div>
                        {MEMBER_TYPES.map((type) => (
                          <EventInput name={`cost[${type.value}]`} label={type.label} type='number' required />
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className='border'></div>
              <p className='title-text-small brown' style={{ margin: '2em auto' }}>
                Organizer
              </p>
              <div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    gridColumnGap: '1vw',
                  }}
                  className={classes.Inputs}
                >
                  <Input
                    name='organizer.name'
                    label='name'
                    required={values && values.organizer ? values.organizer.enabled : false}
                    submitCount={submitCount}
                  />
                  <Input
                    name='organizer.email'
                    label='email'
                    required={values && values.organizer ? values.organizer.enabled : false}
                    submitCount={submitCount}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    gridColumnGap: '1vw',
                  }}
                  className={classes.Inputs}
                >
                  <Input
                    name='organizer.phone'
                    label='phone'
                    required={values && values.organizer ? values.organizer.enabled : false}
                    submitCount={submitCount}
                  />
                  <div className='create-event'>
                    <DropdownComponent
                      name='organizer.enabled'
                      label='Show Organiser'
                      setFieldValue={props.setFieldValue}
                      setFieldTouched={props.setFieldTouched}
                      getFieldMeta={props.getFieldMeta}
                      values={[
                        { value: 0, label: 'No' },
                        { value: 1, label: 'Yes' },
                      ]}
                    />
                  </div>
                </div>
              </div>

              <div className={classes.SubmitButton}>
                <button type='submit' className='btn-primary' disabled={disableSubmit}>
                  Submit
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default CreateEventForm;
